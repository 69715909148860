// eslint-disable-next-line
export default {
  SHEETS_GET: 'SHEETS_GET',
  FLOW_SUMMARY_GET: 'FLOW_SUMMARY_GET',
  FLOW_AGENT_SUMMARY_GET: 'FLOW_AGENT_SUMMARY_GET',
  TEAM_FLOWS_GET: 'TEAM_FLOWS_GET',
  SHEETS_UPDATE: 'SHEETS_UPDATE',
  AUTH_UPDATE: 'AUTH_UPDATE',
  APPOINTMENTS_SET: 'APPOINTMENTS_SET',
  UNSUBSCRIBED_BOTS_GETS: 'UNSUBSCRIBED_BOTS_GETS',
}
