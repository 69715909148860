import types from '../types'
import authHeader from 'helpers/authHeader'
// import flowSummary from 'schema/flowSummary'
import workspaces from 'schema/workspaces'

async function flowSummaryGet(dispatch, payload) {
  // console.log("GEWT FLOW")
  const url = `${process.env.REACT_APP_API_URL}/?url=https://app.channelautomation.com/api/flow-summary?range=${payload.dateFilter}`
  const key = workspaces.data.filter(el => el.id === Number(payload.workspace_id))[0].key
  // console.log("key", key)
  const response = await fetch(url, {
      method: "GET",
      referrerPolicy: 'no-referrer', // no-referrer, *client
      headers: authHeader({key: key})
  })
  .then(res => res.json())
  .then(res => res)
  
  // console.log("response", response)
  // return flowSummary.data
  dispatch({ type: types.FLOW_SUMMARY_GET, payload: response.data })
  return response.data
}

export default flowSummaryGet
