import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress  from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  loadingSpinner: {
    border: "3px solid white",
    borderTop: "3px solid #284781",
    borderBottom: "3px solid #284781",
    borderRadius: "50%",
    width: "15px",
    height: "15px",
    display: "inline-block",
    marginRight: "5px",
    animation: "spin 1s infinite ease-in-out",
  },

  loadingScreenWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
  },

  loadingScreen : {
    border: "5px solid white",
    borderTop: "5px solid black",
    borderBottom: "5px solid black",
    width: "45px",
    height: "45px",
  },

  loadingScreenImgContainer: {
      width: "250px",
      height: "250px",
  },

  commonInfoContainer: {
      width: "100%",
  },

  homeTitleContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
  },

  "@keyframes spin": {
    "from": {
      transform:"rotate(0deg)"
    },
    "to": {
      transform:"rotate(360deg)"
    }
  },
});
function LoadingScreen() {
  const classes = useStyles()
  return (
    <div className={classes.loadingScreenWrapper}>
      {/* <div className={classes.loadingScreenImgContainer}>
      </div> */}
      <CircularProgress size={46}/>
    </div>
  )
}

export default LoadingScreen

