import React, { useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import DashboardIcon from '@material-ui/icons/Dashboard';
import workspaces from 'schema/workspaces'
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
  mainTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "right",
    flexGrow: 2,
  },
  // appBar: {
  //   [theme.breakpoints.up('sm')]: {
  //     // width: `calc(100% - ${drawerWidth}px)`,
  //     // marginLeft: drawerWidth,
  //   },
  // },
}));

export default function AppHeader() {
  const classes = useStyles();
  const navigate = useNavigate();
  const url = window.location.pathname.split("/")
  // const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentDashboard, setCurrentDashboard] = useState("Lead Insigh");
  // eslint-disable-next-line
  const [workSpace, setWorkSpace] = useState(workspaces.data.filter(el => el.id === Number(url[3]))[0])
  
  const changeDashboard = (dashboard) => {
    setCurrentDashboard(dashboard)
    setOpen(false)
    navigate(`/dashboard/${dashboard.toLowerCase().replace(" ", "_")}/${url[3]}`)
  }
  // console.log("url", url)
  useEffect(() => {
    switch(url[2]) {
      case "team_tracker": setCurrentDashboard("Team Tracker"); break;
      case "sales_data": setCurrentDashboard("Sales Data"); break;
      case "lead_insight": setCurrentDashboard("Lead Insight"); break;
      default: break;
    }
  // eslint-disable-next-line
  }, []);

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <List>
        {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
        {/* <ListItem button onClick={() => changeDashboard("Sales Data")}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Sales Data"}/>
        </ListItem> */}
        <ListItem button onClick={() => changeDashboard("Team Tracker")}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Team Tracker"}/>
        </ListItem>
        <ListItem button onClick={() => changeDashboard("Lead Insight")}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Lead Insight"}/>
        </ListItem>
      </List>
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  // const getWorkspaceName = (id) => {
  //   let workspace = workspaces.data.filter(el => el.id === Number(id))
  //   return workspace[0]?.name
  // }

  return (
    <div>
      <React.Fragment key={"right"}>
        <AppBar position="fixed" className={classes.appBar} style={{background: workSpace.main_color}}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setOpen(true)}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {currentDashboard}
            </Typography>
            <div className={classes.mainTitle}>
              {workSpace.name} Dashboard
            </div>
          </Toolbar>
        </AppBar>
        <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}