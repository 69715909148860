// eslint-disable-next-line
export default {
  sheets: null,
  auth: {
    isAuthenticated: false,
  },
  flowSummary: null,
  flowAgentSummary: null,
  teamFlows: null,
  appointmentsSet: 0,
  unsubscribedBots: null,
}

