import types from './types'

function reducer(state, action) {
  switch (action.type) {
    case (types.SHEETS_GET):
      return {
        ...state,
        sheets: action.payload
      }
    case (types.AUTH_UPDATE):
      return {
        ...state,
        auth: { isAuthenticated : action.payload }
      }
    case (types.FLOW_AGENT_SUMMARY_GET):
      return {
        ...state,
        flowAgentSummary: action.payload
      }
    case (types.FLOW_SUMMARY_GET):
      return {
        ...state,
        flowSummary: action.payload
      }
    case (types.TEAM_FLOWS_GET):
      return {
        ...state,
        teamFlows: action.payload
      }
    case (types.APPOINTMENTS_SET):
      return {
        ...state,
        appointmentsSet: action.payload
      }
    case (types.UNSUBSCRIBED_BOTS_GETS):
      console.log("SET")
      console.log("action.payload", action.payload)
      return {
        ...state,
        unsubscribedBots: action.payload
      }

    default:
      throw new Error(`Invalid action type: ${action.type}`)
  }
}

export default reducer
