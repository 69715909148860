import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  errorPageWrapper: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    top: 0,
    zIndex: 9999,
    background: "#f2f4f6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    color: "#3a3669",
    fontSize: "48px",
    fontWeight: "bold",
  },
  description: {
    color: "#3b3761",
    textAlign: "center",
    lineHeight: "32px",
  }
});
function ErrorPage() {
  const classes = useStyles()
  return (
    <div className={classes.errorPageWrapper}>
      <div className={classes.title}>
        We are sorry...
      </div>
      <div className={classes.description}>
        The page you are tring to access has restricted access. <br/>
        Please refer to your system administrator
      </div>
    </div>
  )
}

export default ErrorPage

