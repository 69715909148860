import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppHeader from 'layout/AppHeader'
import routes from 'routes'

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

function AppView() {
  const classes = useStyles();
  

  // console.log("routes", routes)
  return (
    <>
      <AppHeader />
      <main
      >
        <div className={classes.toolbar} />
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
              exact={route.exact}
              render={() => <Navigate exact from="/" to="/dashboard" />}
            />
          ))}
        </Routes>
      </main>
    </>
  )
}

export default AppView

