import React, { useState, useEffect } from 'react';
import { useGlobalState } from 'global-state'
import { Navigate } from 'react-router-dom'
import LoadingScreen from 'components/LoadingScreen'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    // width: "100%",
    padding: "32px",
    gridGap: "12px",
  },
  card: {
    minWidth: 275,
    maxWidth: 350,
  },
  filtersContainer: {
    padding: "32px 32px 0px 32px",
  },
  searchInput: {
    width: "250px",
    marginRight: "12px",
  },
  callerContainer: {
    border: "1px solid #ececec",
    padding: "12px",
    margin: "12px",
    background: "white",
  },
  carrierContainer: {
    border: "1px solid #ececec",
    padding: "12px",
    margin: "12px",
    background: "white",
  },
  carrierInfoContainer: {
    marginLeft: "12px",
  },
  callerInfoContainer: {
    marginLeft: "12px",
    marginBottom: "12px",
  },
  cardContent: {
    padding: 0,
    background: "#f6f6f6",
  },
  title: {
    padding: "13px",
    paddingTop: "18px",
    background: "#00b0ff",
    color: "white",
    fontWeight: "bold",
  }
});

export default function Numbers() {
  const classes = useStyles();
  const [{sheets}, {sheetsGet}] = useGlobalState()
  const [isLoading, setIsLoading] = useState(true)
  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")

  useEffect(() => {
    sheetsGet().then(res => {
      setIsLoading(false)
    })
  // eslint-disable-next-line
  }, []);
  // console.log("sheets", sheets)
  console.log("filter", filter)
  console.log("search", search)

  const filteredRows = () => {
    return sheets && sheets.map((sheet, key) => {
      if(filter === "" ||  (filter !== "" && sheet[filter]?.includes(search)))
      return <Card className={classes.card} variant="outlined" key={"card" + key}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.title}>
                  {sheet.caller_name}
                </Typography>
                <div className={classes.callerContainer}>
                  <div>
                      Caller Info:
                  </div>
                  <div className={classes.callerInfoContainer}>
                    <Typography>
                      Type: {sheet.caller_type}
                    </Typography>
                    <Typography>
                      Country Code: {sheet.country_code}
                    </Typography>
                    <Typography>
                      Phone Number: {sheet.phone_number}
                    </Typography>
                    <Typography>
                      National Format: {sheet.national_format}
                    </Typography>
                  </div>
                </div>
                
                <div className={classes.carrierContainer}>
                  <div>
                      Carrier Info:
                  </div>
                  <div className={classes.carrierInfoContainer}>
                    <Typography>
                      Carrier: {sheet.carrier_name}
                    </Typography>
                    <Typography>
                      Carrier Type: {sheet.carrier_type}
                    </Typography>
                    <Typography>
                      Mobile Country Code: {sheet.carrier_mobile_country_code}
                    </Typography>
                    <Typography>
                      Mobile Network Code: {sheet.carrier_mobile_network_code}
                    </Typography>
                  </div>
                </div>
              </CardContent>
              {/* <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions> */}
            </Card>
      return false
    })
  }

  if(!isLoading) {
    return (
      true ?
      <>
        <div className={classes.filtersContainer}>
          <FormControl variant="outlined" className={classes.formControl} margin="dense">
            <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.searchInput}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              label="Filter By"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"caller_name"}>Caller Name</MenuItem>
              <MenuItem value={"carrier_name"}>Carrier Name</MenuItem>
              <MenuItem value={"carrier_type"}>Carrier Type</MenuItem>
              <MenuItem value={"caller_type"}>Caller Type</MenuItem>
              <MenuItem value={"carrier_mobile_country_code"}>Mobile Country Code</MenuItem>
              <MenuItem value={"carrier_mobile_network_code"}>Mobile Network Code</MenuItem>
              <MenuItem value={"country_code"}>Country Code</MenuItem>
              <MenuItem value={"phone_number"}>Phone Number</MenuItem>
              <MenuItem value={"national_format"}>National Format</MenuItem>
            </Select>
          </FormControl>
          <TextField className={classes.searchInput} label="Search" variant="outlined" margin="dense" value={search} onChange={(e) => setSearch(e.target.value)}/>
        </div>
        <div className={classes.gridContainer}>
          {filteredRows()}
        </div>
      </>
      :
      <Navigate to='/login'  />
    );
  } else {
    return (
      <LoadingScreen />
    );
  }
}
