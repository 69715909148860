// import types from '../types'
// const { GoogleSpreadsheet } = require('google-spreadsheet');
// const shortid = require('shortid');

async function sheetsUpdate(dispatch, payload) {
    // let limitReached = false;
    // const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SHEET_ID);
    // // // use service account creds
    // await doc.useServiceAccountAuth(require('channelauto-7635c08d0942.json'));
    // await doc.loadInfo();
    // const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id]
    
    // var today = new Date();
    // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    // // console.log(sheet)
    // await sheet.getRows().then(res => {
    //     console.log(res)
    //     if(res.length >= 100)
    //         limitReached = true
    // })
    
    // let id = shortid.generate()
    // console.log(limitReached)
    // console.log(id)
    // console.log(date + " " + time)
    // console.log(payload.contact)

    // return await sheet.addRow({ 
    //   carrier_name	
    //   carrier_type	
    //   carrier_error_code	
    //   carrier_mobile_country_code	
    //   carrier_mobile_network_code	
    //   url	
    //   caller_name	
    //   caller_type	
    //   caller_error_code	
    //   country_code	
    //   phone_number	
    //   national_format	
    //   adds_on
    //   ID: id, Name: payload.name, Age: payload.age, Timestamp: date + " " + time, Contact: payload.contact 
    // }).then(res => {
    //     // return({message : "You have successfully registered to the service.", id: id, success: true})
    // }).catch(err => {
    //     // return({message : "An Unexpected Error Occured.", success: false})
    // });
    
}

export default sheetsUpdate
