/*
 * This file makes actions available for use.
 */

import sheetsGet from './sheetsGet'
import sheetsUpdate from './sheetsUpdate'
import authUpdate from './authUpdate'
import flowSummaryGet from './flowSummaryGet'
import flowAgentSummaryGet from './flowAgentSummaryGet'
import teamFlowsGet from './teamFlowsGet'
import unsubscribeBotsGet from './unsubscribeBotsGet'
import appointmentsSetGet from './appointmentsSetGet'

// eslint-disable-next-line
export default {
  sheetsGet,
  sheetsUpdate,
  authUpdate,
  flowSummaryGet,
  flowAgentSummaryGet,
  teamFlowsGet,
  unsubscribeBotsGet,
  appointmentsSetGet,
}
