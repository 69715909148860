import React, { useState, useEffect } from 'react';
import { useGlobalState } from 'global-state'
// import { Navigate } from 'react-router-dom'
import LoadingScreen from 'components/LoadingScreen'
import ErrorPage from 'components/ErrorPage'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import { Users, Speaker } from 'react-feather';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MessageIcon from '@material-ui/icons/Message';
// import DoneIcon from '@material-ui/icons/Done';
import SendIcon from '@material-ui/icons/Send';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import FaceIcon from '@material-ui/icons/Face';
import RedditIcon from '@material-ui/icons/Reddit';
import DraftsIcon from '@material-ui/icons/Drafts';
import EmailIcon from '@material-ui/icons/Email';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress  from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import _set from 'lodash/set'
import _get from 'lodash/get'
import workspaces from 'schema/workspaces'
import Chart from 'react-apexcharts'
// import { sum } from 'lodash';


const columns = [
  { id: 'bot_name', label: 'Channel Name', minWidth: 150, align: "center" },
  // { id: 'flow_ns', label: 'Flow NS', minWidth: 150, align: "center" },
  { id: 'day_agent_messages', label: 'Total Sent Messages', minWidth: 150, align: "center" },
  { id: 'day_note_messages', label: 'Day Note Messages', minWidth: 150, align: "center" },
  { id: 'day_reply_bot_users', label: 'Total Replies Sent', minWidth: 150, align: "center" },
  { id: 'day_assigned', label: 'Day Assigned', minWidth: 150, align: "center" },
  // { id: 'day_done', label: 'Day Done', minWidth: 150, align: "center" },
  { id: 'avg_agent_response_time', label: 'Avg. Agent Response Time', minWidth: 150, align: "center" },
  { id: 'avg_resolve_time', label: 'Avg. Resolve Time', minWidth: 150, align: "center" },
];

const tooltipData = {
  day_reply_bot_users: "The number of contacts with reply by the agent in the day",
  day_agent_messages: "Messages sent by agent in the day",
  day_note_messages: "Private note messages in the day",
  day_assigned: "Number of new contacts created in the day with agent assigned",
  day_done: "Number of new contacts created in the day and marked as done",
  avg_agent_response_time: "Average first agent response time in minutes",
  avg_resolve_time: "average done conversation time in minutes",
}


const useStyles = makeStyles({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    // width: "100%",
    padding: "32px",
    gridGap: "20px",
  },
  errorChartContainer: {
    padding: "32px",
    background: "white",
    borderRadius: "10px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "16px",
    fontWeight: "bold",
  },
  sectionTitle: {
    fontSize: "20px",
    marginBottom: "16px",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: "32px",
  },
  overviewsContainer: {
    display: "flex",
    width: "calc(100% - 40px)",
    justifyContent: "space-between",
    background: "white",
    padding: "20px",
    border: "1px solid #eff0f6",
    boxShadow: "0 4px 25px #a8b4d01a",
    borderRadius: "5px",
  },
  overviewsTitle: {
    color: "#495057",
    fontWeight: 600,
    fontSize: "14px",
  },
  overviewsText: {
    color: "#9aa0ac",
    fontSize: "12px",
    marginBottom: "1rem",
  },
  overviewsValue: {
    fontSize: "30px",
    fontWeight: 600,
  },
  overviewsIconContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  muiGridContainer: {
    padding: "0px 32px",
    paddingBottom: "60px",
  },
  labelsContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  cardContent: {
    background: "white",
    padding: 20,
  },
  cardHeader: {
    background: "white",
    padding: 20,
    borderBlockEnd: "1px solid #eff0f6",
    fontSize: 16,
    color: "rgb(73, 80, 87)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cardFooter: {
    padding: "0px 20px",
    display: "flex",
    width: "100%",
    borderBlockStart: "1px solid #eff0f6",
  },
  cardFooterSection: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px",
  },
  searchInput: {
    width: "250px",
    marginRight: "12px",
  },
  formControl: {
    marginLeft: "12px",
  },
  tooltipIcon: { 
    marginLeft: "6px",
    width: "20px",
    height: "20px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  botFilterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  }
});

const initBotData = {
  total_bot_users: 0,
  day_active_bot_users: 0,
  day_new_bot_users: 0,
  day_total_messages: 0,
  day_out_messages: 0,
  day_done: 0,
  day_email_sent: 0,
  day_email_open: 0,
  day_in_messages: 0,
  avg_agent_response_time: 0,
  total_flows: 1,
}

const apexAreaOptionsInit= {
  chart: {
    id: 'area-datetime',
    type: 'area',
    height: 350,
    zoom: {
      autoScaleYaxis: true
    }
  },
  stroke: {
     width:2,
     colors:["#467fcf"],
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    min: new Date('01 Mar 2012').getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    }
  },
  colors: ["#467fcf"],
  fill: {
    type: 'gradient',
    gradient: {
      type: "vertical",
      shade: "light",
      shadeIntensity: 0,
      opacityFrom: 0.7,
      opacityTo: 0.2,
      stops: [0, 100]
    }
  },
};

const apexBarptionsInit= {
  chart: {
    height: 350,
    type: 'bar',
    // events: {
    //   click: function(chart, w, e) {
    //     // console.log(chart, w, e)
    //   }
    // }
  },
  colors: [],
  plotOptions: {
    bar: {
      columnWidth: '45%',
      distributed: true,
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false
  },
  xaxis: {
    categories: [
    ],
    labels: {
      style: {
        colors: [],
        fontSize: '12px'
      }
    }
  }
};

// const apexAreaSeries= [{
//   name: "series1",
//   type: "area",
//   data: [
//   [1327359600000,30.95],
//   [1327446000000,31.34],
//   [1327532400000,31.18],
//   [1327618800000,31.05],
//   [1327878000000,31.00],
//   [1327964400000,30.95],
//   [1328050800000,31.24],
//   [1328137200000,31.29],
//   [1328223600000,31.85],
//   [1328482800000,31.86],
//   [1328569200000,32.28],
//   [1328655600000,32.10],
//   [1328742000000,32.65],
//   [1328828400000,32.21],
//   [1329087600000,32.35],
//   [1329174000000,32.44],
//   [1329260400000,32.46],
//   [1329346800000,32.86],
//   [1329433200000,32.75],
//   [1329778800000,32.54],
//   [1329865200000,32.33],
//   [1329951600000,32.97],
//   [1330038000000,33.41],
//   [1330297200000,33.27],
//   [1330383600000,33.27],
//   [1330470000000,32.89],
//   [1330556400000,33.10],
//   [1330642800000,33.73],
//   [1330902000000,33.22],
//   [1330988400000,31.99],
//   [1331074800000,32.41],
//   [1331161200000,33.05],
//   [1331247600000,33.64],
//   [1331506800000,33.56],
//   [1331593200000,34.22],
//   [1331679600000,33.77],
//   [1331766000000,34.17],
//   [1331852400000,33.82],
//   [1332111600000,34.51],
//   [1332198000000,33.16],
//   [1332284400000,33.56],
//   [1332370800000,33.71],
//   [1332457200000,33.81],
//   [1332712800000,34.40],
//   [1332799200000,34.63],
//   [1332885600000,34.46],
//   [1332972000000,34.48],
//   [1333058400000,34.31],
//   [1333317600000,34.70],
//   [1333404000000,34.31],
//   [1333490400000,33.46],
//   [1333576800000,33.59],
//   [1333922400000,33.22],
//   [1334008800000,32.61],
//   [1334095200000,33.01],
//   [1334181600000,33.55],
//   [1334268000000,33.18],
//   [1334527200000,32.84],
//   [1334613600000,33.84],
//   [1334700000000,33.39],
//   [1334786400000,32.91],
//   [1334872800000,33.06],
//   [1335132000000,32.62],
//   [1335218400000,32.40],
//   [1335304800000,33.13],
//   [1335391200000,33.26],
//   [1335477600000,33.58],
//   [1335736800000,33.55],
//   [1335823200000,33.77],
//   [1335909600000,33.76],
//   [1335996000000,33.32],
//   [1336082400000,32.61],
//   [1336341600000,32.52],
//   [1336428000000,32.67],
//   [1336514400000,32.52],
//   [1336600800000,31.92],
//   [1336687200000,32.20],
//   [1336946400000,32.23],
//   [1337032800000,32.33],
//   [1337119200000,32.36],
//   [1337205600000,32.01],
//   [1337292000000,31.31],
//   [1337551200000,32.01],
//   [1337637600000,32.01],
//   [1337724000000,32.18],
//   [1337810400000,31.54],
//   [1337896800000,31.60],
//   [1338242400000,32.05],
//   [1338328800000,31.29],
//   [1338415200000,31.05],
//   [1338501600000,29.82],
//   [1338760800000,30.31],
//   [1338847200000,30.70],
//   [1338933600000,31.69],
//   [1339020000000,31.32],
//   [1339106400000,31.65],
//   [1339365600000,31.13],
//   [1339452000000,31.77],
//   [1339538400000,31.79],
//   [1339624800000,31.67],
//   [1339711200000,32.39],
//   [1339970400000,32.63],
//   [1340056800000,32.89],
//   [1340143200000,31.99],
//   [1340229600000,31.23],
//   [1340316000000,31.57],
//   [1340575200000,30.84],
//   [1340661600000,31.07],
//   [1340748000000,31.41],
//   [1340834400000,31.17],
//   [1340920800000,32.37],
//   [1341180000000,32.19],
//   [1341266400000,32.51],
//   [1341439200000,32.53],
//   [1341525600000,31.37],
//   [1341784800000,30.43],
//   [1341871200000,30.44],
//   [1341957600000,30.20],
//   [1342044000000,30.14],
//   [1342130400000,30.65],
//   [1342389600000,30.40],
//   [1342476000000,30.65],
//   [1342562400000,31.43],
//   [1342648800000,31.89],
//   [1342735200000,31.38],
//   [1342994400000,30.64],
//   [1343080800000,30.02],
//   [1343167200000,30.33],
//   [1343253600000,30.95],
//   [1343340000000,31.89],
//   [1343599200000,31.01],
//   [1343685600000,30.88],
//   [1343772000000,30.69],
//   [1343858400000,30.58],
//   [1343944800000,32.02],
//   [1344204000000,32.14],
//   [1344290400000,32.37],
//   [1344376800000,32.51],
//   [1344463200000,32.65],
//   [1344549600000,32.64],
//   [1344808800000,32.27],
//   [1344895200000,32.10],
//   [1344981600000,32.91],
//   [1345068000000,33.65],
//   [1345154400000,33.80],
//   [1345413600000,33.92],
//   [1345500000000,33.75],
//   [1345586400000,33.84],
//   [1345672800000,33.50],
//   [1345759200000,32.26],
//   [1346018400000,32.32],
//   [1346104800000,32.06],
//   [1346191200000,31.96],
//   [1346277600000,31.46],
//   [1346364000000,31.27],
//   [1346709600000,31.43],
//   [1346796000000,32.26],
//   [1346882400000,32.79],
//   [1346968800000,32.46],
//   [1347228000000,32.13],
//   [1347314400000,32.43],
//   [1347400800000,32.42],
//   [1347487200000,32.81],
//   [1347573600000,33.34],
//   [1347832800000,33.41],
//   [1347919200000,32.57],
//   [1348005600000,33.12],
//   [1348092000000,34.53],
//   [1348178400000,33.83],
//   [1348437600000,33.41],
//   [1348524000000,32.90],
//   [1348610400000,32.53],
//   [1348696800000,32.80],
//   [1348783200000,32.44],
//   [1349042400000,32.62],
//   [1349128800000,32.57],
//   [1349215200000,32.60],
//   [1349301600000,32.68],
//   [1349388000000,32.47],
//   [1349647200000,32.23],
//   [1349733600000,31.68],
//   [1349820000000,31.51],
//   [1349906400000,31.78],
//   [1349992800000,31.94],
//   [1350252000000,32.33],
//   [1350338400000,33.24],
//   [1350424800000,33.44],
//   [1350511200000,33.48],
//   [1350597600000,33.24],
//   [1350856800000,33.49],
//   [1350943200000,33.31],
//   [1351029600000,33.36],
//   [1351116000000,33.40],
//   [1351202400000,34.01],
//   [1351638000000,34.02],
//   [1351724400000,34.36],
//   [1351810800000,34.39],
//   [1352070000000,34.24],
//   [1352156400000,34.39],
//   [1352242800000,33.47],
//   [1352329200000,32.98],
//   [1352415600000,32.90],
//   [1352674800000,32.70],
//   [1352761200000,32.54],
//   [1352847600000,32.23],
//   [1352934000000,32.64],
//   [1353020400000,32.65],
//   [1353279600000,32.92],
//   [1353366000000,32.64],
//   [1353452400000,32.84],
//   [1353625200000,33.40],
//   [1353884400000,33.30],
//   [1353970800000,33.18],
//   [1354057200000,33.88],
//   [1354143600000,34.09],
//   [1354230000000,34.61],
//   [1354489200000,34.70],
//   [1354575600000,35.30],
//   [1354662000000,35.40],
//   [1354748400000,35.14],
//   [1354834800000,35.48],
//   [1355094000000,35.75],
//   [1355180400000,35.54],
//   [1355266800000,35.96],
//   [1355353200000,35.53],
//   [1355439600000,37.56],
//   [1355698800000,37.42],
//   [1355785200000,37.49],
//   [1355871600000,38.09],
//   [1355958000000,37.87],
//   [1356044400000,37.71],
//   [1356303600000,37.53],
//   [1356476400000,37.55],
//   [1356562800000,37.30],
//   [1356649200000,36.90],
//   [1356908400000,37.68],
//   [1357081200000,38.34],
//   [1357167600000,37.75],
//   [1357254000000,38.13],
//   [1357513200000,37.94],
//   [1357599600000,38.14],
//   [1357686000000,38.66],
//   [1357772400000,38.62],
//   [1357858800000,38.09],
//   [1358118000000,38.16],
//   [1358204400000,38.15],
//   [1358290800000,37.88],
//   [1358377200000,37.73],
//   [1358463600000,37.98],
//   [1358809200000,37.95],
//   [1358895600000,38.25],
//   [1358982000000,38.10],
//   [1359068400000,38.32],
//   [1359327600000,38.24],
//   [1359414000000,38.52],
//   [1359500400000,37.94],
//   [1359586800000,37.83],
//   [1359673200000,38.34],
//   [1359932400000,38.10],
//   [1360018800000,38.51],
//   [1360105200000,38.40],
//   [1360191600000,38.07],
//   [1360278000000,39.12],
//   [1360537200000,38.64],
//   [1360623600000,38.89],
//   [1360710000000,38.81],
//   [1360796400000,38.61],
//   [1360882800000,38.63],
//   [1361228400000,38.99],
//   [1361314800000,38.77],
//   [1361401200000,38.34],
//   [1361487600000,38.55],
//   [1361746800000,38.11],
//   [1361833200000,38.59],
//   [1361919600000,39.60],
//   ]
// }]

export default function TeamTrackerDashboard(props) {
  const classes = useStyles();
  const [{teamFlows, unsubscribedBots}, {flowAgentSummaryGet, teamFlowsGet, flowSummaryGet, unsubscribeBotsGet}] = useGlobalState()
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isBotLoading, setIsBotLoading] = useState(true)
  const [bestAgent, setBestAgent] = useState({agent_name: "", data: 0})
  const [botData, setBotData] = useState(initBotData)
  const [bestBot, setBestBot] = useState({flow_ns: "", data: 0})
  const [apexAreaSeriesContacts, setApexAreaSeriesContacts] = useState({
    name: "Active Contacts",
    type: "area",
    data: []
  })
  const [apexAreaSeriesAvgResponseTime, setApexAreaSeriesAvgResponseTime] = useState({
    name: "Average Response Time",
    type: "area",
    data: []
  })
  const [apexBarMessagesSeries, setApexBarMessagesSeries] = useState({
    name: "Total Messages Sent",
    data: []
  })
  const [apexBarRepliesSeries, setApexBarRepliesSeries] = useState({
    name: "Total Replies Sent",
    data: []
  })
  
  const [apexBarOptions, setApexBarOptions] = useState(apexBarptionsInit)
  const [apexBar2Options, setApexBar2Options] = useState(apexBarptionsInit)
  const [apexAreaOptionsContacts, setApexAreaOptionsContacts] = useState(apexAreaOptionsInit)
  const [apexAreaOptionsAvgResponseTime, setApexAreaOptionsAvgResponseTime] = useState(apexAreaOptionsInit)
  
  const [groupedFlowAgentSummary, setGroupedFlowAgentSummary] = useState([])
  const [noFilterFlowAgentSummary, setNoFilterFlowAgentSummary] = useState([])
  // const [groupedFlowBots, setGroupedFlowBots] = useState([])
  const [currentBots, setCurrentBots] = useState([])
  const [filter, setFilter] = useState("no_filter")
  const [botFilter, setBotFilter] = useState("no_filter")
  const [dateFilter, setDateFilter] = useState("last_7_days")
  const [botDateFilter, setBotDateFilter] = useState("last_7_days")
  const workspace_id = window.location.pathname.split("/dashboard/team_tracker/")[1] === undefined ? "" : window.location.pathname.split("/dashboard/team_tracker/")[1]
  const workspace = workspaces.data.filter(el => el.id === Number(workspace_id))[0]
  const shouldAccess = workspaces.data.some(el => el.id === Number(workspace_id))

  useEffect(() => {
    if(shouldAccess) {
      setIsLoading(true)
      let groupedFlows = {}
      let noFilterFlows = []
      let agentPerformance = {}
      let localBestAgent = {agent_name:"", data: 0}
      let localBestBot = {flow_ns:"", data: 0}
      flowAgentSummaryGet({dateFilter: dateFilter, workspace_id: workspace_id}).then((res) => {
        res && res.forEach((summary, index) => {

          // console.log("summary.agent_id === 2894 && workspace_id !== 5648", summary.agent_id === 2894 && Number(workspace_id) !== 5648)
          if(summary.agent_id === 2894 && Number(workspace_id) !== 5648) //Skip if Channel Auto Admin
            return
          
          if(summary.agent_id in groupedFlows) {
            // groupedFlows[summary.agent_id].push(summary)
            let groupedFlowIndex = groupedFlows[summary.agent_id].findIndex(x => x.flow_ns === summary?.flow_ns)
            
            if(groupedFlowIndex !== -1) {
              groupedFlows[summary.agent_id][groupedFlowIndex] = {
                ...groupedFlows[summary.agent_id][groupedFlowIndex],
                avg_agent_response_time: groupedFlows[summary.agent_id][groupedFlowIndex]?.avg_agent_response_time + summary?.avg_agent_response_time,
                avg_resolve_time: groupedFlows[summary.agent_id][groupedFlowIndex]?.avg_resolve_time + summary?.avg_resolve_time,
                day_agent_messages: groupedFlows[summary.agent_id][groupedFlowIndex]?.day_agent_messages + summary?.day_agent_messages,
                day_assigned: groupedFlows[summary.agent_id][groupedFlowIndex]?.day_assigned + summary?.day_assigned,
                day_done: groupedFlows[summary.agent_id][groupedFlowIndex]?.day_done + summary?.day_done,
                day_note_messages: groupedFlows[summary.agent_id][groupedFlowIndex]?.day_note_messages + summary?.day_note_messages,
                day_reply_bot_users: groupedFlows[summary.agent_id][groupedFlowIndex]?.day_reply_bot_users + summary?.day_reply_bot_users,
              }
            } else {
              groupedFlows[summary.agent_id].push(summary)
            }

            // console.log("noFilterFlows", noFilterFlows)
            // agentPerformance[summary.agent_id]["day_reply_bot_users"] = (agentPerformance[summary.agent_id]["day_reply_bot_users"] ? agentPerformance[summary.agent_id]["day_reply_bot_users"] : 0) + summary.day_reply_bot_users 
            _set(agentPerformance, [summary.agent_id, "day_reply_bot_users"], _get(agentPerformance, [summary.agent_id, "day_reply_bot_users"], 0) + summary.day_reply_bot_users)
            // _set(agentPerformance, [summary.agent_id, "day_agent_messages"], _get(agentPerformance, [summary.agent_id, "day_agent_messages"], 0) + summary.day_agent_messages)
            // if(_get(agentPerformance, [summary.agent_id, "day_agent_messages"]) < summary.day_agent_messages) {
            //   _set(agentPerformance, [summary.agent_id, "day_agent_messages"], summary.day_agent_messages)
            //   _set(agentPerformance, [summary.agent_id, "flow_ns"], summary.flow_ns)
            //   _set(agentPerformance, [summary.agent_id, "agent_name"], summary.agent.name)
            // }
          } else {
            //Group flows
            groupedFlows[summary.agent_id] = [summary]
            
            //Record agent performance
            _set(agentPerformance, [summary.agent_id, "day_reply_bot_users"], summary.day_reply_bot_users)
            _set(agentPerformance, [summary.agent_id, "day_agent_messages"], summary.day_agent_messages)
            _set(agentPerformance, [summary.agent_id, "flow_ns"], summary.flow_ns)
            _set(agentPerformance, [summary.agent_id, "agent_name"], summary.agent.name)
          }


          let noFilterFlowIndex = noFilterFlows.findIndex(x => x.flow_ns === summary.flow_ns)
          //Handle No Filter Array
          if(noFilterFlowIndex !== -1) {
            
            noFilterFlows[noFilterFlowIndex] = {
              ...noFilterFlows[noFilterFlowIndex],
              avg_agent_response_time: noFilterFlows[noFilterFlowIndex]?.avg_agent_response_time + summary?.avg_agent_response_time,
              avg_resolve_time: noFilterFlows[noFilterFlowIndex]?.avg_resolve_time + summary?.avg_resolve_time,
              day_agent_messages: noFilterFlows[noFilterFlowIndex]?.day_agent_messages + summary?.day_agent_messages,
              day_assigned: noFilterFlows[noFilterFlowIndex]?.day_assigned + summary?.day_assigned,
              day_done: noFilterFlows[noFilterFlowIndex]?.day_done + summary?.day_done,
              day_note_messages: noFilterFlows[noFilterFlowIndex]?.day_note_messages + summary?.day_note_messages,
              day_reply_bot_users: noFilterFlows[noFilterFlowIndex]?.day_reply_bot_users + summary?.day_reply_bot_users,
              total_flows: noFilterFlows[noFilterFlowIndex]?.total_flows + 1
            }
            // console.log("summary", summary)
            // _set(agentPerformance, [summary.agent_id, "day_reply_bot_users"], _get(agentPerformance, [summary.agent_id, "day_reply_bot_users"], 0) + summary.day_reply_bot_users)
            
            // agentPerformance[summary.agent_id]["day_reply_bot_users"] = (agentPerformance[summary.agent_id]["day_reply_bot_users"] ? agentPerformance[summary.agent_id]["day_reply_bot_users"] : 0) + summary.day_reply_bot_users 

            // if(_get(agentPerformance, [summary.agent_id, "day_agent_messages"]) < summary.day_agent_messages) {
            //   _set(agentPerformance, [summary.agent_id, "day_agent_messages"], summary.day_agent_messages)
            //   _set(agentPerformance, [summary.agent_id, "flow_ns"], summary.flow_ns)
            //   _set(agentPerformance, [summary.agent_id, "agent_name"], summary.agent.name)
            // }

          } else {
            noFilterFlows.push({...summary, total_flows: 1})
            // console.log("noFilterFlows", noFilterFlows)
            // _set(agentPerformance, [summary.agent_id, "day_reply_bot_users"], summary.day_reply_bot_users)
            // _set(agentPerformance, [summary.agent_id, "day_agent_messages"], summary.day_agent_messages)
            // _set(agentPerformance, [summary.agent_id, "flow_ns"], summary.flow_ns)
            // _set(agentPerformance, [summary.agent_id, "agent_name"], summary.agent.name)
          }
        })
        // console.log("agentPerformance", agentPerformance)
        // console.log("noFilterFlows", noFilterFlows)
        Object.keys(agentPerformance).map((agent) => {
          if(localBestAgent.data < agentPerformance[agent].day_reply_bot_users) {
            localBestAgent.agent_name = agentPerformance[agent].agent_name
            localBestAgent.data = agentPerformance[agent].day_reply_bot_users
          }
          // if(localBestBot.data < agentPerformance[agent].day_agent_messages) {
          //   localBestBot.flow_ns = agentPerformance[agent].flow_ns
          //   localBestBot.data = agentPerformance[agent].day_agent_messages
          // }
          return false
          // console.log("agent", agent)
        })

        let copyOfNoFilterFlows = JSON.parse(JSON.stringify(noFilterFlows))
        //Set Best Bot
        noFilterFlows.forEach((bot,index) => {
          if(localBestBot.data < bot.day_agent_messages) {
            localBestBot.data = bot.day_agent_messages
            localBestBot.flow_ns = bot.flow_ns
          }
          let time = (copyOfNoFilterFlows[index].avg_agent_response_time / copyOfNoFilterFlows[index].total_flows)
          copyOfNoFilterFlows[index].avg_agent_response_time = new Date(time * 1000).toISOString().slice(14, 19)
          copyOfNoFilterFlows[index].avg_resolve_time = new Date(time * 1000).toISOString().slice(14, 19)
        })


        let localBarData = {messagesSent: [], repliesSent: []}
    
        Object.keys(groupedFlows).forEach((key, index) => {
          let messagesSentCounter = 0
          let repliesSentCounter = 0
          groupedFlows[key].forEach((bot, index) => {
            messagesSentCounter += bot.day_agent_messages
            repliesSentCounter += bot.day_reply_bot_users
            if(index === groupedFlows[key].length -1) {
              localBarData.messagesSent.push(messagesSentCounter)
              localBarData.repliesSent.push(repliesSentCounter)
            }
          })
        })

        let colorsArray = generateColors(Object.keys(groupedFlows).length)
        let categories = Object.keys(groupedFlows).map((key) => groupedFlows[key][0].agent.name)
        setApexBarOptions({
          ...apexBarOptions, 
          xaxis: {
            ...apexBarOptions.xaxis, 
            categories: categories,
            labels: {
              ...apexBarOptions.xaxis.labels,
              style: {
                ...apexBarOptions.xaxis.style,
                colors: colorsArray
              }
            }
          },
          colors: colorsArray,
        })
        setApexBar2Options({
          ...apexBar2Options, 
          xaxis: {
            ...apexBar2Options.xaxis, 
            categories: categories,
            labels: {
              ...apexBar2Options.xaxis.labels,
              style: {
                ...apexBar2Options.xaxis.style,
                colors: colorsArray
              }
            }
          },
          colors: colorsArray,
        })
        // setApexBarSeries({...apexBarSeries, data: localBarData})
        setApexBarMessagesSeries({...apexBarMessagesSeries, data: localBarData.messagesSent})
        setApexBarRepliesSeries({...apexBarRepliesSeries, data: localBarData.repliesSent})

        console.log("test", colorsArray)
        console.log("apexBarOptions", apexBarOptions)
        console.log("apexBarMessagesSeries", apexBarMessagesSeries)
        console.log("localBarData", localBarData)

        // console.log("copyOfNoFilterFlows", copyOfNoFilterFlows)
        setBestAgent(localBestAgent)
        setBestBot(localBestBot)
        setNoFilterFlowAgentSummary(copyOfNoFilterFlows)
        setGroupedFlowAgentSummary(groupedFlows)
        setIsLoading(false)
        console.log("errorhere?")
      })
      teamFlowsGet({workspace_id: workspace_id}).then(() => setPageIsLoading(false))
    } else {
      setPageIsLoading(false)
    }
  // eslint-disable-next-line
  }, [dateFilter]);
  // console.log("apexBarOptions1", apexBarOptions)
  // console.log("apexBarMessagesSeries1", apexBarMessagesSeries)
  // console.log("apexBarRepliesSeries", apexBarRepliesSeries)
  useEffect(() => {
    if(shouldAccess) {
      setIsBotLoading(true)
      let localBotData = Object.assign({}, initBotData)
      let bots = []
      let dates = []
      let localCurrentBots = JSON.parse(JSON.stringify(currentBots))
      flowSummaryGet({dateFilter: botDateFilter, workspace_id: workspace_id}).then((res) => {
        let localRes = JSON.parse(JSON.stringify(res));
        localRes.sort((a, b) => (a.flow_ns > b.flow_ns) ? 1 : -1);

        if(botFilter !== "no_filter") {
          localRes = localRes.filter(el => el.flow_ns === botFilter)
        }

        // console.log("localRes", localRes)
        // console.log("botFilter", botFilter)

      
        localRes && localRes.forEach((summary, index) => {

          if(!localCurrentBots.includes(summary.flow_ns))
            localCurrentBots.push(summary.flow_ns)

          //Check if date exist in dates array
          let dateIndex = dates.findIndex(x => x.date === summary?.summary_date)
          if(dateIndex === -1) //Push summary if date does not exist
            dates.push({  
              date: summary?.summary_date, 
              total_bot_users: summary?.total_bot_users, 
              day_active_bot_users: summary?.day_active_bot_users,
              avg_agent_response_time: summary?.avg_agent_response_time,
              total_flows: 1
          })
          else { //Update values if date exist
            dates[dateIndex].total_bot_users += summary?.total_bot_users
            dates[dateIndex].day_active_bot_users += summary?.day_active_bot_users
            dates[dateIndex].avg_agent_response_time += summary?.avg_agent_response_time
            dates[dateIndex].total_flows += 1
          }
          // bots.push(summary.flow_ns)
          if(index === 0) {
            
            bots.push(summary)
            // bots.push(summary.flow_ns)
            // bots.push(summary.summary_date)
            // bots.push(summary.total_bot_users)
          }

          if((bots[0] && bots[0].flow_ns === summary.flow_ns)) {
            if(new Date(bots[0].summary_date).getTime() < new Date(summary.summary_date).getTime()) {
              bots.pop()
              bots.push(summary)
              // bots[1] = summary.summary_date
              // bots[2] = summary.total_bot_users //Save total bot users if date is higher
            }
          } else {
            //Update Bot users if on new flow_ns
            localBotData.total_bot_users += bots[0].total_bot_users
            // localBotData.day_active_bot_users += bots[0].day_active_bot_users
            // localBotData.day_new_bot_users += bots[0].day_new_bot_users
            // localBotData.day_total_messages += bots[0].day_total_messages
            // localBotData.day_out_messages += bots[0].day_out_messages
            // localBotData.day_done += bots[0].day_done
            // localBotData.avg_resolve_time += bots[0].avg_resolve_time
            // console.log("bots[0].day_active_bot_users", bots[0].day_active_bot_users)
            bots.pop()
            bots.push(summary)
            // localBotData.total_bot_users += bots[2]
            // bots.pop()
            // bots.pop()
            // bots.pop()

            // bots.push(summary.flow_ns)
            // bots.push(summary.summary_date)
            // bots.push(summary.total_bot_users)
          }

          if(index === localRes.length - 1) {
            localBotData.total_bot_users += bots[0].total_bot_users
            // localBotData.day_active_bot_users += bots[0].day_active_bot_users
            // localBotData.day_new_bot_users += bots[0].day_new_bot_users
            // localBotData.day_total_messages += bots[0].day_total_messages
            // localBotData.day_out_messages += bots[0].day_out_messages
            // localBotData.day_done += bots[0].day_done
            // localBotData.avg_resolve_time += bots[0].avg_resolve_time
            bots.pop()
            // localBotData.total_bot_users += bots[2]
            // bots.pop()
            // bots.pop()
            // bots.pop()
            dates.forEach((date, index) => {
              let time = (date.avg_agent_response_time / date.total_flows)
              dates[index].avg_agent_response_time = time//new Date(time * 1000).toISOString().slice(14, 19)
            })
          }

          // console.log("dates", dates)

          localBotData.day_active_bot_users += summary.day_active_bot_users
          localBotData.day_new_bot_users += summary.day_new_bot_users
          localBotData.day_total_messages += summary.day_total_messages
          localBotData.day_out_messages += summary.day_out_messages
          localBotData.day_done += summary.day_done
          localBotData.day_email_sent += summary.day_email_sent
          localBotData.day_email_open += summary.day_email_open
          localBotData.day_in_messages += summary.day_in_messages
        })
        // console.log("dates", dates)
        let formattedDatesContacts = []
        let formattedDatesAvgResponseTime = []
        dates.forEach((date) => {
          formattedDatesContacts.push([new Date(date.date).getTime(), date.day_active_bot_users])
          formattedDatesAvgResponseTime.push([new Date(date.date).getTime(), date.avg_agent_response_time])
        })

        
        // console.log("formattedDatesContacts", formattedDatesContacts)
        // console.log("formattedDatesAvgResponseTime", formattedDatesAvgResponseTime)

        setApexAreaOptionsContacts({
          ...apexAreaOptionsContacts, 
          colors: [workspace.main_color], 
          xaxis: {...apexAreaOptionsContacts.xaxis, min: new Date(dates[dates.length -1].date).getTime()},
          stroke: {...apexAreaOptionsContacts.stroke, colors: [workspace.main_color],}
        })
        setApexAreaOptionsAvgResponseTime({
          ...apexAreaOptionsAvgResponseTime, 
          colors: ["#467fcf"], 
          xaxis: {
            ...apexAreaOptionsAvgResponseTime.xaxis, 
            min: new Date(dates[dates.length -1].date).getTime(), 
          },
          yaxis: {
            ...apexAreaOptionsAvgResponseTime.yaxis, 
            labels: {
              formatter: function (value) {
                return new Date(value * 1000).toISOString().slice(14, 19);
              }
            }
          },
          stroke: {...apexAreaOptionsAvgResponseTime.stroke, colors: ["#467fcf"],}
        })
        
        setApexAreaSeriesContacts({...apexAreaSeriesContacts, data: formattedDatesContacts})
        setApexAreaSeriesAvgResponseTime({...apexAreaSeriesAvgResponseTime, data: formattedDatesAvgResponseTime})
        setCurrentBots(localCurrentBots)
        setIsBotLoading(false)
        // console.log("testCount", testCount)
        // console.log("localCurrentBots", localCurrentBots)
      })
      // console.log("localBotData", localBotData)
      setBotData(localBotData)
      
    } else {
      setPageIsLoading(false)
    }
    unsubscribeBotsGet({workspace: workspace})
  // eslint-disable-next-line
  }, [botDateFilter, botFilter]);
  // console.log("apexAreaSeries", apexAreaSeries)
  // console.log("apexAreaOptions", apexAreaOptions)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getBotName = (flow_ns) => {
    let bot = teamFlows.filter(el => el.flow_ns === flow_ns)
    // console.log("bot", bot)
    return bot[0]?.name
  }
  
  const displayBots = () => {
    let data = null
    filter === "no_filter" ?
      data = noFilterFlowAgentSummary && noFilterFlowAgentSummary.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    :
      data = groupedFlowAgentSummary[filter] && groupedFlowAgentSummary[filter].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    // console.log("filter", filter)
    // console.log("data", data)
    return data === undefined ? [] : data
  }

  const generateColors = (numberOfColors) => {
    let colors = []
    for(let i = numberOfColors; i > 0; i--) {
      colors.push("#" + Math.floor(Math.random()*16777215).toString(16))
    }
    
    return colors
  }

  if(unsubscribedBots){
    console.log("botFilter")
    console.log(unsubscribedBots.filter(el => el.flow_ns === botFilter).map(bot => bot))
    console.log("bots" , unsubscribedBots.filter(el => el.flow_ns === botFilter).map((bot) => bot))
  }

  if(!pageIsLoading) {
    return (
      shouldAccess ?
      <>       
        <div className={classes.sectionTitle}>Agents Overview</div>
        <Grid container className={classes.muiGridContainer} spacing={3}>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #5eba00"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Highest Performing Agent - {bestAgent.agent_name}
                </div>
                <div className={classes.overviewsText}>
                  Total Replies Sent
                </div>
                <div className={classes.overviewsValue}>
                  {bestAgent.data}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <FaceIcon className={classes.overviewsIcon} style={{color: "#5eba00"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #467fcf"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Highest Performing Channel - {getBotName(bestBot.flow_ns)}
                </div>
                <div className={classes.overviewsText}>
                  Total Sent Messages
                </div>
                <div className={classes.overviewsValue}>
                  {bestBot.data}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <RedditIcon style={{color: "#467fcf"}}/>
                </div>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={3}>
            <div className={classes.overviewsContainer}>
              <div>
                <div className={classes.overviewsTitle}>
                  Task
                </div>
                <div className={classes.overviewsText}>
                  Overview of this month
                </div>
                <div className={classes.overviewsValue}>
                  6,738
                </div>
              </div>
              <div className={classes.overviewsIcon}>
                <div>
                  <FileText style={{color: "#ffc107"}}/>
                </div>
              </div>
            </div>
          </Grid> */}
          {/* <Grid item xs={3}>
            <div className={classes.overviewsContainer}>
              <div>
                <div className={classes.overviewsTitle}>
                  Earnings
                </div>
                <div className={classes.overviewsText}>
                  Overview of this month
                </div>
                <div className={classes.overviewsValue}>
                  $8,963
                </div>
              </div>
              <div className={classes.overviewsIcon}>
                <div>
                  <ExternalLink style={{color: "#f66"}}/>
                </div>
              </div>
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <Card className={classes.root} elevation={0}>
              <div
                className={classes.cardHeader}
              >
                <FormControl variant="outlined" className={classes.formControl} margin="dense">
                  <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.searchInput}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    label="Filter"
                  >
                    <MenuItem value="no_filter">
                      <em>None</em>
                    </MenuItem>
                    {
                      Object.keys(groupedFlowAgentSummary).map((key) => {
                        return <MenuItem value={key} key={key}>{groupedFlowAgentSummary[key][0]?.agent?.name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} margin="dense">
                  <InputLabel id="demo-simple-select-outlined-label">Range</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.searchInput}
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                    label="Range"
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    <MenuItem value={"yesterday"} key={"yesterday"}>Yesterday</MenuItem>
                    <MenuItem value={"last_7_days"} key={"last_7_days"}>Last 7 Days</MenuItem>
                    <MenuItem value={"last_week"} key={"last_week"}>Last Week</MenuItem>
                    <MenuItem value={"last_30_days"} key={"last_30_days"}>Last 30 Days</MenuItem>
                    <MenuItem value={"last_month"} key={"last_month"}>Last Month</MenuItem>
                  </Select>
                </FormControl>
                 {
                    isLoading && <CircularProgress size={32}/>
                 }
              </div>
              
              <CardContent className={classes.cardContent}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div className={classes.flexCenter}>
                              {column.label}
                              {
                                column.id !== "bot_name" &&
                                  <Tooltip title={tooltipData[column.id]} arrow>
                                    <InfoIcon style={{color: "#006193"}} className={classes.tooltipIcon}/>
                                  </Tooltip>
                              }
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayBots().map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                             
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {
                                    column.id === "bot_name" ?
                                      getBotName(row.flow_ns)
                                    :
                                      column.format && typeof value === 'number' ? column.format(value) : value
                                  }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    filter === "no_filter" ?
                      noFilterFlowAgentSummary && noFilterFlowAgentSummary.length ? 
                        noFilterFlowAgentSummary.length 
                      : 
                        0
                    : 
                      groupedFlowAgentSummary[filter] && groupedFlowAgentSummary[filter].length ? 
                        groupedFlowAgentSummary[filter].length 
                      : 
                        0
                  }//Finish no filter
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className={classes.root} elevation={0}>
              <div
                className={classes.cardHeader}
              >
                Agents Total Messages Sent
              </div>
              <CardContent className={classes.cardContent}>
                <Chart options={apexBarOptions} series={[apexBarMessagesSeries]} width={"100%"} type="bar" height={295} />
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={6}>
            <Card className={classes.root} elevation={0}>
              <div
                className={classes.cardHeader}
              >
                Agents Total Replies Sent
              </div>
              <CardContent className={classes.cardContent}>
                <Chart options={apexBar2Options} series={[apexBarRepliesSeries]} width={"100%"} type="bar" height={295} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.sectionTitle}>Channels Overview</div>
          </Grid>
          
          <Grid item xs={12}>
            <div className={classes.botFilterContainer}>
              <FormControl variant="outlined" className={classes.formControl} margin="dense">
                <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className={classes.searchInput}
                  value={botFilter}
                  onChange={(e) => setBotFilter(e.target.value)}
                  label="Filter"
                >
                  <MenuItem value="no_filter">
                    <em>None</em>
                  </MenuItem>
                  {
                    currentBots.map((bot, index) => {
                      return <MenuItem value={bot} key={index}>{getBotName(bot)}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl} margin="dense">
                <InputLabel id="demo-simple-select-outlined-label">Range</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className={classes.searchInput}
                  value={botDateFilter}
                  onChange={(e) => setBotDateFilter(e.target.value)}
                  label="Range"
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem> */}
                  <MenuItem value={"yesterday"} key={"yesterday"}>Yesterday</MenuItem>
                  <MenuItem value={"last_7_days"} key={"last_7_days"}>Last 7 Days</MenuItem>
                  <MenuItem value={"last_week"} key={"last_week"}>Last Week</MenuItem>
                  <MenuItem value={"last_30_days"} key={"last_30_days"}>Last 30 Days</MenuItem>
                  <MenuItem value={"last_month"} key={"last_month"}>Last Month</MenuItem>
                </Select>
              </FormControl>
              {/* {
                isBotLoading && <CircularProgress size={32}/>
              } */}
            </div>
          </Grid>

          <Grid item xs={6}>
            <Card className={classes.root} elevation={0}>
              <div
                className={classes.cardHeader}
              >
                Active Contacts Timeline
              </div>
              <CardContent className={classes.cardContent}>
                <Chart options={apexAreaOptionsContacts} series={[apexAreaSeriesContacts]} width={"100%"} height={295} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className={classes.root} elevation={0}>
              <div
                className={classes.cardHeader}
              >
                Average Response Time in Minutes
              </div>
              <CardContent className={classes.cardContent}>
                <Chart options={apexAreaOptionsAvgResponseTime} series={[apexAreaSeriesAvgResponseTime]} width={"100%"} height={295} />
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={3}>
              <div className={classes.overviewsContainer} style={{border: "2px solid #467fcf"}}>
                <div>
                  <div className={classes.overviewsTitle}>
                    Total Contacts
                  </div>
                  <div className={classes.overviewsText}>
                    Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                  </div>
                  <div className={classes.overviewsValue}>
                    { isBotLoading ? <CircularProgress size={32}/> : botData.total_bot_users}
                  </div>
                </div>
                <div className={classes.overviewsIconContainer}>
                  <div>
                    <PeopleAltIcon className={classes.overviewsIcon} style={{color: "#467fcf"}}/>
                  </div>
                </div>
              </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #5eba00"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total Active Contacts
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  { isBotLoading ? <CircularProgress size={32}/> : botData.day_active_bot_users}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <PersonPinIcon className={classes.overviewsIcon} style={{color: "#5eba00"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #3ec7e8"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total New Contacts
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  {isBotLoading ? <CircularProgress size={32}/> : botData.day_new_bot_users}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <PersonAddIcon className={classes.overviewsIcon} style={{color: "#3ec7e8"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #ffca4a"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total Messages
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  { isBotLoading ? <CircularProgress size={32}/> : botData.day_total_messages}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <MessageIcon className={classes.overviewsIcon} style={{color: "#ffca4a"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #ff6666"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total Out Messages
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  {isBotLoading ? <CircularProgress size={32}/> :  botData.day_out_messages}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <SendIcon className={classes.overviewsIcon} style={{color: "#ff6666"}}/>
                </div>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #867efc"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total Done
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  {isBotLoading ? <CircularProgress size={32}/> :  botData.day_done}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <DoneIcon className={classes.overviewsIcon} style={{color: "#867efc"}}/>
                </div>
              </div>
            </div>
          </Grid> */}
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #2bb4a6"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total In Messages
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  { isBotLoading ? <CircularProgress size={32}/> : botData.day_in_messages}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <TextsmsIcon className={classes.overviewsIcon} style={{color: "#2bb4a6"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #28afd0"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total Email Sent
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  { isBotLoading ? <CircularProgress size={32}/> : botData.day_email_sent}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <EmailIcon className={classes.overviewsIcon} style={{color: "#28afd0"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #467fcf"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total Email Open
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  { isBotLoading ? <CircularProgress size={32}/> : botData.day_email_open}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <DraftsIcon className={classes.overviewsIcon} style={{color: "#467fcf"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #ffc34d"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total Number of Unsubscribes
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  { isBotLoading ? <CircularProgress size={32}/> : unsubscribedBots ? botFilter === "no_filter" ? unsubscribedBots.length : unsubscribedBots.filter(el => el.flow_ns === botFilter).length : 0}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <UnsubscribeIcon className={classes.overviewsIcon} style={{color: "#ffc34d"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer} style={{border: "2px solid #621b93"}}>
              <div>
                <div className={classes.overviewsTitle}>
                  Total Responses - Total Unsubscribes
                </div>
                <div className={classes.overviewsText}>
                  Since {botDateFilter.charAt(0).toUpperCase() + botDateFilter.replaceAll("_", " ").slice(1)}
                </div>
                <div className={classes.overviewsValue}>
                  { isBotLoading ? <CircularProgress size={32}/> : botData.day_in_messages - (unsubscribedBots ? botFilter === "no_filter" ? unsubscribedBots.length : unsubscribedBots.filter(el => el.flow_ns === botFilter).length : 0)}
                </div>
              </div>
              <div className={classes.overviewsIconContainer}>
                <div>
                  <UnsubscribeIcon className={classes.overviewsIcon} style={{color: "#621b93"}}/>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
      :
      <ErrorPage />
    );
  } else {
    return (
      <LoadingScreen />
    );
  }
}
