import React, { useState, useEffect } from 'react';
import { useGlobalState } from 'global-state'
// import { Navigate } from 'react-router-dom'
import LoadingScreen from 'components/LoadingScreen'
import ErrorPage from 'components/ErrorPage'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Calendar, Users } from 'react-feather'; //FileText, ExternalLink
import { Bar, Doughnut } from 'react-chartjs-2';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import workspaces from 'schema/workspaces'

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Custom Chart Title'
    }
  },
};


export const doughNutdata = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


const useStyles = makeStyles({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    // width: "100%",
    padding: "32px",
    gridGap: "20px",
  },
  errorChartContainer: {
    padding: "32px",
    background: "white",
    borderRadius: "10px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "16px",
    fontWeight: "bold",
  },
  mainTitle: {
    fontSize: "28px",
    marginBottom: "16px",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: "32px",
  },
  overviewsContainer: {
    display: "flex",
    width: "calc(100% - 40px)",
    justifyContent: "space-between",
    background: "white",
    padding: "20px",
    border: "1px solid #eff0f6",
    boxShadow: "0 4px 25px #a8b4d01a",
    borderRadius: "5px",
  },
  overviewsTitle: {
    color: "#495057",
    fontWeight: 600,
    fontSize: "14px",
  },
  overviewsText: {
    color: "#9aa0ac",
    fontSize: "12px",
    marginBottom: "1rem",
  },
  overviewsValue: {
    fontSize: "30px",
    fontWeight: 600,
  },
  overviewsIcon: {
    display: "flex",
    alignItems: "flex-end",
  },
  muiGridContainer: {
    padding: "0px 32px",
    paddingTop: "60px",
    // paddingBottom: "60px",
  },
  labelsContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  cardContent: {
    background: "white",
    padding: 20,
  },
  cardHeader: {
    background: "white",
    padding: 20,
    borderBlockEnd: "1px solid #eff0f6",
    fontSize: 16,
    color: "rgb(73, 80, 87)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cardFooter: {
    padding: "0px 20px",
    display: "flex",
    width: "100%",
    borderBlockStart: "1px solid #eff0f6",
  },
  cardFooterSection: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px",
  },
  cardRoot: {
    height: "100%",
  }
});

const columns = [
  { id: 'caller_name', label: 'Name', minWidth: 150, align: "center" },
  { id: 'carrier_type', label: 'Carrier Type', minWidth: 150, align: "center" },
  { id: 'carrier_name', label: 'Carrier Name', minWidth: 150, align: "center" },
  { id: 'national_format', label: 'Phone Number', minWidth: 150, align: "center" },
  { id: 'caller_type', label: 'Caller Type', minWidth: 150, align: "center" },
  { id: 'country_code', label: 'Country Code', minWidth: 150, align: "center" },
];

export default function LeadInsightDashboard(props) {
  const classes = useStyles();
  const [{sheets, appointmentsSet}, {sheetsGet, appointmentsSetGet}] = useGlobalState()
  const [isLoading, setIsLoading] = useState(true)
  // const [numbersCount, setNumbersCount] = useState({fake: 0, real: 0})
  const [numbersData, setNumbersData] = useState({fake: [], real: []})
  const [carriers, setCarriers] = useState([])
  const [leadsCount, setLeadsCount] = useState(0)
  // const [callerErrors, setCallerErrors] = useState([])
  // const [carrierErrors, setCarrierErrors] = useState([])
  const workspace_id = window.location.pathname.split("/dashboard/lead_insight/")[1] === undefined ? "" : window.location.pathname.split("/dashboard/lead_insight/")[1]
  const workspace = workspaces.data.filter(el => el.id === Number(workspace_id))[0]
  const shouldAccess = workspaces.data.some(el => el.id === Number(workspace_id))
  // console.log("workspace_id", workspace_id)
  // console.log("shouldAccess", shouldAccess)
  // console.log(window.location.pathname.split("/dashboard/"))
  useEffect(() => {
    if(shouldAccess) {
      sheetsGet().then(res => {
        setIsLoading(false)
      })
      appointmentsSetGet({workspace: workspace})
    } else {
      setIsLoading(false)
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let real = []
    let fake = []
    let carriersCounts = {};
    let callerErrorsCounts = {};
    let carrierErrorsCounts = {};
    let leadCounter = 0
    sheets && sheets.map((sheet) => {
      if(window.location.pathname.split("/dashboard/")[1]) {
        if(workspace_id && Number(workspace_id) !== Number(sheet.workspace_id))
          return false
      }

      if(sheet.carrier_name === "AT&T Mobility" || sheet.carrier_name === "Verizon" || sheet.carrier_name === "T-Mobile Usa, Inc.")
        real.push(sheet)
      else
        fake.push(sheet)
      
      carriersCounts[sheet.carrier_name] = (carriersCounts[sheet.carrier_name] || 0) + 1;
      callerErrorsCounts[sheet.caller_error_code] = (callerErrorsCounts[sheet.caller_error_code] || 0) + 1;
      carrierErrorsCounts[sheet.carrier_error_code] = (carrierErrorsCounts[sheet.carrier_error_code] || 0) + 1;
      leadCounter++
      return false
    })

    if(sheets)
      setLeadsCount(leadCounter)

    setNumbersData({fake: fake, real: real})
    setCarriers(carriersCounts)
    // setCallerErrors(callerErrorsCounts)
    // setCarrierErrors(carrierErrorsCounts)
    // setNumbersCount({fake: fake, real:real})
  // eslint-disable-next-line
  }, [sheets]);
  console.log("appointmentsSet", appointmentsSet)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const getWorkspaceName = (id) => {
  //   let workspace = workspaces.data.filter(el => el.id === Number(id))
  //   return workspace[0]?.name
  // }

  if(!isLoading) {
    return (
      shouldAccess ?
      <>       
        {/* <div className={classes.mainTitle}>{getWorkspaceName(workspace_id)} Dashboard</div> */}
        <Grid container className={classes.muiGridContainer} spacing={3}>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer}>
              <div>
                <div className={classes.overviewsTitle}>
                  Appointments Set
                </div>
                <div className={classes.overviewsText}>
                  Overview of this month
                </div>
                <div className={classes.overviewsValue}>
                  {/* {Number(workspace_id) !== 21000 ? 0 : appointmentsSet} */}
                  {appointmentsSet}
                </div>
              </div>
              <div className={classes.overviewsIcon}>
                <div>
                  <Calendar style={{color: "#467fcf"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer}>
              <div>
                <div className={classes.overviewsTitle}>
                  Number of Leads
                </div>
                <div className={classes.overviewsText}>
                  Overview of this month
                </div>
                <div className={classes.overviewsValue}>
                  {leadsCount}
                </div>
              </div>
              <div className={classes.overviewsIcon}>
                <div>
                  <Users style={{color: "#5eba00"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={3}>
          </Grid>
          {/* <Grid item xs={3}>
            <div className={classes.overviewsContainer}>
              <div>
                <div className={classes.overviewsTitle}>
                  Task
                </div>
                <div className={classes.overviewsText}>
                  Overview of this month
                </div>
                <div className={classes.overviewsValue}>
                  6,738
                </div>
              </div>
              <div className={classes.overviewsIcon}>
                <div>
                  <FileText style={{color: "#ffc107"}}/>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.overviewsContainer}>
              <div>
                <div className={classes.overviewsTitle}>
                  Earnings
                </div>
                <div className={classes.overviewsText}>
                  Overview of this month
                </div>
                <div className={classes.overviewsValue}>
                  $8,963
                </div>
              </div>
              <div className={classes.overviewsIcon}>
                <div>
                  <ExternalLink style={{color: "#f66"}}/>
                </div>
              </div>
            </div>
          </Grid> */}
          <Grid item xs={6}>
            <Card className={[classes.root, classes.cardRoot].join(" ")} elevation={0}>
              <div
                className={classes.cardHeader}
              >
                Phone Number Data
              </div>
              <CardContent className={classes.cardContent}>
                <Bar options={options} data={{
                    labels: ['Phone Numbers'],
                    datasets: [
                      {
                        label: 'Real Phone Numbers',
                        data: [numbersData.real.length],
                        backgroundColor: 'rgba(219, 242, 242, 0.5)',
                      },
                      {
                        label: 'Fake Phone Numbers',
                        data: [numbersData.fake.length],
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      },
                    ],
                  }} 
                />
                <div className={classes.labelsContainer}>
                  <div className={classes.labelItem}>
                    Real Phone Numbers: {numbersData.real.length} ({leadsCount !== 0 ? ((numbersData.real.length / leadsCount) * 100).toFixed(2) : 0}%)
                  </div>
                  <div className={classes.labelItem}>
                    Fake Phone Numbers: {numbersData.fake.length} ({leadsCount !== 0 ? ((numbersData.fake.length / leadsCount) * 100).toFixed(2) : 0}%)
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={[classes.root, classes.cardRoot].join(" ")} elevation={0}>
              <div
                className={classes.cardHeader}
              >
                Carrier Name Data
              </div>
              <CardContent className={classes.cardContent}>
                <Doughnut data={{
                    labels: carriers ? Object.keys(carriers).map((key) => key) : [],
                    datasets: [
                      {
                        label: '# of Votes',
                        data: carriers ? Object.keys(carriers).map((key) => carriers[key]) : [],
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                          'rgba(255, 99, 132, 1)',
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 206, 86, 1)',
                          'rgba(75, 192, 192, 1)',
                          'rgba(153, 102, 255, 1)',
                          'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }} 
                />
              </CardContent>
            </Card>
            {/* <div className={classes.errorChartContainer}>
              <div className={classes.title}>Caller Errors Data</div>
              <Doughnut data={{
                  labels: callerErrors ? Object.keys(callerErrors).map((key) => key === "null" ? "No error": key) : [],
                  datasets: [
                    {
                      label: '# of Votes',
                      data: callerErrors ? Object.keys(callerErrors).map((key) => callerErrors[key] === "null" ? "No error": callerErrors[key]) : [],
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }} 
              />
            </div>
            <div className={classes.errorChartContainer}>
              <div className={classes.title}>Carrier Errors Data</div>
              <Doughnut data={{
                  labels: carrierErrors ? Object.keys(carrierErrors).map((key) => key === "null" ? "No error": key) : [],
                  datasets: [
                    {
                      label: '# of Votes',
                      data: carrierErrors ? Object.keys(carrierErrors).map((key) => carrierErrors[key] === "null" ? "No error": carrierErrors[key]) : [],
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }} 
              />
            </div> */}
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.root} elevation={0}>
              <div
                className={classes.cardHeader}
              >
                Fake Numbers
              </div>
              
              <CardContent className={classes.cardContent}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div className={classes.flexCenter}>
                              {column.label}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {numbersData.fake.map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {
                                    column.format && typeof value === 'number' ? column.format(value) : value
                                  }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={numbersData.fake.length}//Finish no filter
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
      :
      <ErrorPage />
    );
  } else {
    return (
      <LoadingScreen />
    );
  }
}
