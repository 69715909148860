import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import AppView from 'layout/AppView'
import { GlobalStateProvider } from 'global-state'
import './App.scss';

function App() {
  return (
    <Router basename="/">
      <GlobalStateProvider>
          <AppView />
      </GlobalStateProvider>
    </Router>
  )
}

export default App
