import React, { useState, useEffect } from 'react';
import { useGlobalState } from 'global-state'
import { Navigate } from 'react-router-dom'
import LoadingScreen from 'components/LoadingScreen'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Layers, Users, FileText, ExternalLink, ArrowUp, ArrowDown, Briefcase, TrendingUp, Truck } from 'react-feather'; //CarretUp, CarretDown
// import { Bar, Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
// import Typography from '@material-ui/core/Typography';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Custom Chart Title'
    }
  },
};


export const doughNutdata = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const apexBarOptions = {
  chart:{
    height:395,
    width:"100%",
    type:"bar",
    stacked:!0,
    stackType:"100%",
    toolbar:{show:!1}
  },
  plotOptions:{bar:{horizontal:!0}},
  grid:{borderColor:"rgba(119, 119, 142, 0.08)"},
  stroke:{width:1,colors:["#fff"]},
  colors:["#467fcf","#5eba00","#ffca4a","#ff6666","#867efc","#3ec7e8","#2bb4a6","#00C851"],
  title:{text:""},
  xaxis:{categories:[2013,2014,2015,2016,2017,2018,2019,2020,2021],axisBorder:{show:!0,color:"rgba(119, 119, 142, 0.05)"},
  axisTicks:{show:!0,color:"rgba(119, 119, 142, 0.05)"}},
  tooltip:{y:{formatter:function(val){return val+"K"}}},
  fill:{opacity:1},
  legend:{position:"top",horizontalAlign:"left",offsetX:40}
}

const apexBarSeries = [
  {name:"Usa",data:[44,55,41,37,22,43,32]},
  {name:"India",data:[53,32,33,52,13,43,32]},
  {name:"Russia",data:[17,14,13,18,15,12,14]},
  {name:"Canada",data:[18,15,20,12,15,9,18]},
  {name:"Japan",data:[10,15,13,8,16,9,18]},
  {name:"Australia",data:[20,25,22,19,22,15,16]},
  {name:"Mexico",data:[18,24,16,22,13,17,21]},
  {name:"Germany",data:[25,12,19,32,25,24,30]}
]

const apexAreaOptions= {
  chart: {
    id: 'area-datetime',
    type: 'area',
    height: 350,
    zoom: {
      autoScaleYaxis: true
    }
  },
  stroke: {
     width:2,
     colors:["#467fcf"],
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    min: new Date('01 Mar 2012').getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    }
  },
  colors: ["#467fcf"],
  fill: {
    type: 'gradient',
    gradient: {
      type: "vertical",
      shade: "light",
      shadeIntensity: 0,
      opacityFrom: 0.7,
      opacityTo: 0.2,
      stops: [0, 100]
    }
  },
};

const apexAreaSeries= [{
  name: "series1",
  type: "area",
  data: [
  [1327359600000,30.95],
  [1327446000000,31.34],
  [1327532400000,31.18],
  [1327618800000,31.05],
  [1327878000000,31.00],
  [1327964400000,30.95],
  [1328050800000,31.24],
  [1328137200000,31.29],
  [1328223600000,31.85],
  [1328482800000,31.86],
  [1328569200000,32.28],
  [1328655600000,32.10],
  [1328742000000,32.65],
  [1328828400000,32.21],
  [1329087600000,32.35],
  [1329174000000,32.44],
  [1329260400000,32.46],
  [1329346800000,32.86],
  [1329433200000,32.75],
  [1329778800000,32.54],
  [1329865200000,32.33],
  [1329951600000,32.97],
  [1330038000000,33.41],
  [1330297200000,33.27],
  [1330383600000,33.27],
  [1330470000000,32.89],
  [1330556400000,33.10],
  [1330642800000,33.73],
  [1330902000000,33.22],
  [1330988400000,31.99],
  [1331074800000,32.41],
  [1331161200000,33.05],
  [1331247600000,33.64],
  [1331506800000,33.56],
  [1331593200000,34.22],
  [1331679600000,33.77],
  [1331766000000,34.17],
  [1331852400000,33.82],
  [1332111600000,34.51],
  [1332198000000,33.16],
  [1332284400000,33.56],
  [1332370800000,33.71],
  [1332457200000,33.81],
  [1332712800000,34.40],
  [1332799200000,34.63],
  [1332885600000,34.46],
  [1332972000000,34.48],
  [1333058400000,34.31],
  [1333317600000,34.70],
  [1333404000000,34.31],
  [1333490400000,33.46],
  [1333576800000,33.59],
  [1333922400000,33.22],
  [1334008800000,32.61],
  [1334095200000,33.01],
  [1334181600000,33.55],
  [1334268000000,33.18],
  [1334527200000,32.84],
  [1334613600000,33.84],
  [1334700000000,33.39],
  [1334786400000,32.91],
  [1334872800000,33.06],
  [1335132000000,32.62],
  [1335218400000,32.40],
  [1335304800000,33.13],
  [1335391200000,33.26],
  [1335477600000,33.58],
  [1335736800000,33.55],
  [1335823200000,33.77],
  [1335909600000,33.76],
  [1335996000000,33.32],
  [1336082400000,32.61],
  [1336341600000,32.52],
  [1336428000000,32.67],
  [1336514400000,32.52],
  [1336600800000,31.92],
  [1336687200000,32.20],
  [1336946400000,32.23],
  [1337032800000,32.33],
  [1337119200000,32.36],
  [1337205600000,32.01],
  [1337292000000,31.31],
  [1337551200000,32.01],
  [1337637600000,32.01],
  [1337724000000,32.18],
  [1337810400000,31.54],
  [1337896800000,31.60],
  [1338242400000,32.05],
  [1338328800000,31.29],
  [1338415200000,31.05],
  [1338501600000,29.82],
  [1338760800000,30.31],
  [1338847200000,30.70],
  [1338933600000,31.69],
  [1339020000000,31.32],
  [1339106400000,31.65],
  [1339365600000,31.13],
  [1339452000000,31.77],
  [1339538400000,31.79],
  [1339624800000,31.67],
  [1339711200000,32.39],
  [1339970400000,32.63],
  [1340056800000,32.89],
  [1340143200000,31.99],
  [1340229600000,31.23],
  [1340316000000,31.57],
  [1340575200000,30.84],
  [1340661600000,31.07],
  [1340748000000,31.41],
  [1340834400000,31.17],
  [1340920800000,32.37],
  [1341180000000,32.19],
  [1341266400000,32.51],
  [1341439200000,32.53],
  [1341525600000,31.37],
  [1341784800000,30.43],
  [1341871200000,30.44],
  [1341957600000,30.20],
  [1342044000000,30.14],
  [1342130400000,30.65],
  [1342389600000,30.40],
  [1342476000000,30.65],
  [1342562400000,31.43],
  [1342648800000,31.89],
  [1342735200000,31.38],
  [1342994400000,30.64],
  [1343080800000,30.02],
  [1343167200000,30.33],
  [1343253600000,30.95],
  [1343340000000,31.89],
  [1343599200000,31.01],
  [1343685600000,30.88],
  [1343772000000,30.69],
  [1343858400000,30.58],
  [1343944800000,32.02],
  [1344204000000,32.14],
  [1344290400000,32.37],
  [1344376800000,32.51],
  [1344463200000,32.65],
  [1344549600000,32.64],
  [1344808800000,32.27],
  [1344895200000,32.10],
  [1344981600000,32.91],
  [1345068000000,33.65],
  [1345154400000,33.80],
  [1345413600000,33.92],
  [1345500000000,33.75],
  [1345586400000,33.84],
  [1345672800000,33.50],
  [1345759200000,32.26],
  [1346018400000,32.32],
  [1346104800000,32.06],
  [1346191200000,31.96],
  [1346277600000,31.46],
  [1346364000000,31.27],
  [1346709600000,31.43],
  [1346796000000,32.26],
  [1346882400000,32.79],
  [1346968800000,32.46],
  [1347228000000,32.13],
  [1347314400000,32.43],
  [1347400800000,32.42],
  [1347487200000,32.81],
  [1347573600000,33.34],
  [1347832800000,33.41],
  [1347919200000,32.57],
  [1348005600000,33.12],
  [1348092000000,34.53],
  [1348178400000,33.83],
  [1348437600000,33.41],
  [1348524000000,32.90],
  [1348610400000,32.53],
  [1348696800000,32.80],
  [1348783200000,32.44],
  [1349042400000,32.62],
  [1349128800000,32.57],
  [1349215200000,32.60],
  [1349301600000,32.68],
  [1349388000000,32.47],
  [1349647200000,32.23],
  [1349733600000,31.68],
  [1349820000000,31.51],
  [1349906400000,31.78],
  [1349992800000,31.94],
  [1350252000000,32.33],
  [1350338400000,33.24],
  [1350424800000,33.44],
  [1350511200000,33.48],
  [1350597600000,33.24],
  [1350856800000,33.49],
  [1350943200000,33.31],
  [1351029600000,33.36],
  [1351116000000,33.40],
  [1351202400000,34.01],
  [1351638000000,34.02],
  [1351724400000,34.36],
  [1351810800000,34.39],
  [1352070000000,34.24],
  [1352156400000,34.39],
  [1352242800000,33.47],
  [1352329200000,32.98],
  [1352415600000,32.90],
  [1352674800000,32.70],
  [1352761200000,32.54],
  [1352847600000,32.23],
  [1352934000000,32.64],
  [1353020400000,32.65],
  [1353279600000,32.92],
  [1353366000000,32.64],
  [1353452400000,32.84],
  [1353625200000,33.40],
  [1353884400000,33.30],
  [1353970800000,33.18],
  [1354057200000,33.88],
  [1354143600000,34.09],
  [1354230000000,34.61],
  [1354489200000,34.70],
  [1354575600000,35.30],
  [1354662000000,35.40],
  [1354748400000,35.14],
  [1354834800000,35.48],
  [1355094000000,35.75],
  [1355180400000,35.54],
  [1355266800000,35.96],
  [1355353200000,35.53],
  [1355439600000,37.56],
  [1355698800000,37.42],
  [1355785200000,37.49],
  [1355871600000,38.09],
  [1355958000000,37.87],
  [1356044400000,37.71],
  [1356303600000,37.53],
  [1356476400000,37.55],
  [1356562800000,37.30],
  [1356649200000,36.90],
  [1356908400000,37.68],
  [1357081200000,38.34],
  [1357167600000,37.75],
  [1357254000000,38.13],
  [1357513200000,37.94],
  [1357599600000,38.14],
  [1357686000000,38.66],
  [1357772400000,38.62],
  [1357858800000,38.09],
  [1358118000000,38.16],
  [1358204400000,38.15],
  [1358290800000,37.88],
  [1358377200000,37.73],
  [1358463600000,37.98],
  [1358809200000,37.95],
  [1358895600000,38.25],
  [1358982000000,38.10],
  [1359068400000,38.32],
  [1359327600000,38.24],
  [1359414000000,38.52],
  [1359500400000,37.94],
  [1359586800000,37.83],
  [1359673200000,38.34],
  [1359932400000,38.10],
  [1360018800000,38.51],
  [1360105200000,38.40],
  [1360191600000,38.07],
  [1360278000000,39.12],
  [1360537200000,38.64],
  [1360623600000,38.89],
  [1360710000000,38.81],
  [1360796400000,38.61],
  [1360882800000,38.63],
  [1361228400000,38.99],
  [1361314800000,38.77],
  [1361401200000,38.34],
  [1361487600000,38.55],
  [1361746800000,38.11],
  [1361833200000,38.59],
  [1361919600000,39.60],
  ]
}]


const useStyles = makeStyles({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    // width: "100%",
    padding: "32px",
    gridGap: "12px",
  },
  errorChartContainer: {
    padding: "32px",
    background: "white",
    borderRadius: "10px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "16px",
    fontWeight: "bold",
  },
  mainTitle: {
    fontSize: "28px",
    marginBottom: "16px",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: "32px",
  },
  overviewsContainer: {
    display: "flex",
    width: "calc(100% - 40px)",
    justifyContent: "space-between",
    background: "white",
    padding: "20px",
    border: "1px solid #eff0f6",
    boxShadow: "0 4px 25px #a8b4d01a",
    borderRadius: "5px",
  },
  overviewsTitle: {
    color: "#495057",
    fontWeight: 600,
    fontSize: "14px",
  },
  overviewsText: {
    color: "#9aa0ac",
    fontSize: "12px",
    marginBottom: "1rem",
  },
  overviewsValue: {
    fontSize: "30px",
    fontWeight: 600,
  },
  overviewsIcon: {
    display: "flex",
    alignItems: "flex-end",
  },
  muiGridContainer: {
    // gridGap: "20px",
  },
  mainWrapper: {
    padding: "24px",
  },
  progressBarStriped: {
    backgroundImage: "linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)",
    backgroundSize: "1rem 1rem",
    height: "0.25rem",
    width: "50%",
    animation: "progress-bar-stripes 1s linear infinite",
  },
  progress: {
    height: "0.25rem",
    overflow: "hidden",
    fontSize: ".703125rem",
    backgroundColor: "#0000000d",
    borderRadius: "7px",
    position: "relative",
  },
  textSuccess: {
    color: "#15c763",
  },
  textDanger: {
    color: "#f66",
  },
  textMutedDark: {
    color: "#8b94a7",
  },
  fwSemibold: {
    fontWeight: 600,
  },
  totalTitle: {
    fontSize: 14,
  },
  textDark: {
    color: "#495057",
  },
  totalAmount: {
    fontSize: 24,
    marginTop: 8,
    marginBottom: 4,
  },
  textMuted: {
    color: "#9aa0ac",
  },
  totalPercent: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
  },
  totalContainer: {
    padding: 20,
    backgroundColor: "white",
  },
  totalContentContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  hw12: {
    height: 12,
    width: 12,
  },
  mr4: {
    marginRight: 4,
  },
  roundedCircle: {
    borderRadius: "50%",
  },
  textPrimary: {
    color: "#467fcf",
  },
  p12 :{
    padding: 12,
  },
  bgBlue: {
    background: "rgba(70, 127, 207, 0.2)",
  },
  bgPrimaryBlue: {
    backgroundColor: "#467fcf",
  },
  bgGreen: {
    background: "#5eba0033",
  },
  bgSecondaryGreen: {
    backgroundColor: "#5eba00",
  },
  bgTransparentRed: {
    background: "#ec2d3833",
  },
  bgRed: {
    backgroundColor: "#ec2d38",
  },
  textSecondary: {
    color: "#5eba00",
  },
  borderEnd: {
    borderInlineEnd: "1px solid #eff0f6",
  },
  cardContent: {
    background: "white",
    padding: 20,
  },
  cardHeader: {
    background: "white",
    padding: 20,
    borderBlockEnd: "1px solid #eff0f6",
    fontSize: 16,
    color: "rgb(73, 80, 87)",
  },
  cardFooter: {
    padding: "0px 20px",
    display: "flex",
    width: "100%",
    borderBlockStart: "1px solid #eff0f6",
  },
  cardFooterSection: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fs14:{
    fontSize: 14,
  },
  hw24:{
    height: 24,
    width: 24,
  },
  noPad: {
    padding: 0,
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export default function SalesDataDashboard(props) {
  const classes = useStyles();
  const [{sheets}, {sheetsGet}] = useGlobalState()
  const [isLoading, setIsLoading] = useState(true)
  // const [numbersCount, setNumbersCount] = useState({fake: 0, real: 0})
  // const [carriers, setCarriers] = useState([])
  // const [callerErrors, setCallerErrors] = useState([])
  // const [carrierErrors, setCarrierErrors] = useState([])
  const client = window.location.pathname.split("/dashboard/sales_data/")[1] === undefined ? "" : window.location.pathname.split("/dashboard/sales_data")[1].replace("%20", " ")
  console.log(window.location.pathname.split("/dashboard/"))
  useEffect(() => {
    sheetsGet().then(res => {
      setIsLoading(false)
    })
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // let real = 0
    // let fake = 0
    // let carriersCounts = {};
    // let callerErrorsCounts = {};
    // let carrierErrorsCounts = {};
    // sheets && sheets.map((sheet) => {
    //   if(window.location.pathname.split("/dashboard/")[1]) {
    //     if(client && client !== sheet.workspace_name)
    //       return false
    //   }

    //   if(sheet.carrier_name === "AT&T Mobility" || sheet.carrier_name === "Verizon" || sheet.carrier_name === "T-Mobile Usa, Inc.")
    //     real++
    //   else
    //     fake++
      
    //   carriersCounts[sheet.carrier_name] = (carriersCounts[sheet.carrier_name] || 0) + 1;
    //   callerErrorsCounts[sheet.caller_error_code] = (callerErrorsCounts[sheet.caller_error_code] || 0) + 1;
    //   carrierErrorsCounts[sheet.carrier_error_code] = (carrierErrorsCounts[sheet.carrier_error_code] || 0) + 1;
    // })
    // setCarriers(carriersCounts)
    // setCallerErrors(callerErrorsCounts)
    // setCarrierErrors(carrierErrorsCounts)
    // setNumbersCount({fake: fake, real:real})
  }, [sheets]);
  // console.log("carriers", carriers)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if(!isLoading) {
    return (
      true ?
      <>       
        <div className={classes.mainTitle}>{client} Dashboard</div>
        <div className={classes.mainWrapper}>
          <Grid container className={classes.muiGridContainer} spacing={3}>
            <Grid item xs={3}>
              <div className={classes.overviewsContainer}>
                <div>
                  <div className={classes.overviewsTitle}>
                    Projects
                  </div>
                  <div className={classes.overviewsText}>
                    Overview of this month
                  </div>
                  <div className={classes.overviewsValue}>
                    3,456
                  </div>
                </div>
                <div className={classes.overviewsIcon}>
                  <div>
                    <Layers style={{color: "#467fcf"}}/>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.overviewsContainer}>
                <div>
                  <div className={classes.overviewsTitle}>
                    Employees
                  </div>
                  <div className={classes.overviewsText}>
                    Overview of this month
                  </div>
                  <div className={classes.overviewsValue}>
                    4,738
                  </div>
                </div>
                <div className={classes.overviewsIcon}>
                  <div>
                    <Users style={{color: "#5eba00"}}/>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.overviewsContainer}>
                <div>
                  <div className={classes.overviewsTitle}>
                    Task
                  </div>
                  <div className={classes.overviewsText}>
                    Overview of this month
                  </div>
                  <div className={classes.overviewsValue}>
                    6,738
                  </div>
                </div>
                <div className={classes.overviewsIcon}>
                  <div>
                    <FileText style={{color: "#ffc107"}}/>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.overviewsContainer}>
                <div>
                  <div className={classes.overviewsTitle}>
                    Earnings
                  </div>
                  <div className={classes.overviewsText}>
                    Overview of this month
                  </div>
                  <div className={classes.overviewsValue}>
                    $8,963
                  </div>
                </div>
                <div className={classes.overviewsIcon}>
                  <div>
                    <ExternalLink style={{color: "#f66"}}/>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <div className={[classes.totalContainer, classes.borderEnd].join(" ")}>
                        <div className={classes.totalContentContainer}>
                          <div className={classes.totalContent}>
                            <div className={[classes.totalTitle, classes.textMutedDark, classes.fwSemibold].join(" ")}>
                              Total Purchase
                            </div>
                            <div className={[classes.totalAmount, classes.textDark, classes.fwSemibold].join(" ")}>
                              $7,483
                            </div>
                            <div className={classes.totalPercent}>
                              <ArrowUp className={[classes.textSuccess, classes.hw12, classes.mr4].join(" ")}/>23% in this year
                            </div>
                          </div>
                          <div className={[classes.totalIcon, classes.roundedCircle, classes.bgBlue, classes.p12, classes.hw24].join(" ")}>
                            <Briefcase className={[classes.textPrimary].join(" ")}/>
                          </div>
                        </div>
                        <div className={[classes.progress].join(" ")}><div className={[classes.progressBarStriped, classes.bgPrimaryBlue].join(" ")}></div></div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={[classes.totalContainer, classes.borderEnd].join(" ")}>
                        <div className={classes.totalContentContainer}>
                          <div className={classes.totalContent}>
                            <div className={[classes.totalTitle, classes.textMutedDark, classes.fwSemibold].join(" ")}>
                              Total Orders
                            </div>
                            <div className={[classes.totalAmount, classes.textDark, classes.fwSemibold].join(" ")}>
                              65,457
                            </div>
                            <div className={classes.totalPercent}>
                              <ArrowUp className={[classes.textSuccess, classes.hw12, classes.mr4].join(" ")}/>13% in this year
                            </div>
                          </div>
                          <div className={[classes.totalIcon, classes.roundedCircle, classes.bgGreen, classes.p12, classes.hw24].join(" ")}>
                            <Truck className={[classes.textSecondary].join(" ")}/>
                          </div>
                        </div>
                        <div className={[classes.progress].join(" ")}><div className={[classes.progressBarStriped, classes.bgSecondaryGreen].join(" ")}></div></div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={[classes.totalContainer, classes.borderEnd].join(" ")}>
                        <div className={classes.totalContentContainer}>
                          <div className={classes.totalContent}>
                            <div className={[classes.totalTitle, classes.textMutedDark, classes.fwSemibold].join(" ")}>
                              Total Sales
                            </div>
                            <div className={[classes.totalAmount, classes.textDark, classes.fwSemibold].join(" ")}>
                              $6,128
                            </div>
                            <div className={classes.totalPercent}>
                              <ArrowDown className={[classes.textDanger, classes.hw12, classes.mr4].join(" ")}/>12% in this year
                            </div>
                          </div>
                          <div className={[classes.totalIcon, classes.roundedCircle, classes.bgTransparentRed, classes.p12, classes.hw24].join(" ")}>
                            <TrendingUp className={[classes.textDanger].join(" ")}/>
                          </div>
                        </div>
                        <div className={[classes.progress].join(" ")}><div className={[classes.progressBarStriped, classes.bgRed].join(" ")}></div></div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.root} elevation={0}>
                    <div
                      className={classes.cardHeader}
                    >
                      Sales Overview
                    </div>
                    <CardContent className={classes.cardContent}>
                      <Chart options={apexAreaOptions} series={apexAreaSeries} width={"100%"} height={295} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
               <Card className={classes.root} elevation={0}>
                <div
                  className={classes.cardHeader}
                >
                  Sales Country Wise
                </div>
                <CardContent className={classes.cardContent}>
                  <Chart options={apexBarOptions} series={apexBarSeries} type="bar" width={"100%"} height={370} />
                </CardContent>
                <CardActions className={classes.noPad}>
                  <div className={classes.cardFooter}>
                    <div className={[classes.cardFooterSection, classes.borderEnd].join(" ")}>
                      <div>
                        Monthly Average
                      </div>
                      <div className={classes.flexCenter}>
                        <div>
                          2,186
                        </div>
                        <div className={classes.flexCenter}>
                          <ArrowDropUpIcon className={[classes.textSuccess].join(" ")}/><span className={[classes.textSuccess, classes.fwSemibold, classes.fs14].join(" ")}>0.48%</span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.cardFooterSection}>
                      <div>
                        Weekly Average
                      </div>
                      <div className={classes.flexCenter}>
                        <div>
                          1,068
                        </div>
                        <div className={classes.flexCenter}>
                          <ArrowDropDownIcon className={[classes.textDanger].join(" ")}/><span className={[classes.textDanger, classes.fwSemibold, classes.fs14].join(" ")}>0.20%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.root} elevation={0}>
                <div
                  className={classes.cardHeader}
                >
                  Agents Overview
                </div>
                <CardContent className={classes.cardContent}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        {/* <div className={classes.gridContainer}>
          <div className={classes.errorChartContainer}>
            <div className={classes.title}>Phone Number Data</div>
            <Bar options={options} data={{
                labels: ['Phone Numbers'],
                datasets: [
                  {
                    label: 'Real Phone Numbers',
                    data: [numbersCount.real],
                    backgroundColor: 'rgba(219, 242, 242, 0.5)',
                  },
                  {
                    label: 'Fake Phone Numbers',
                    data: [numbersCount.fake],
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  },
                ],
              }} 
            />
          </div>
          <div className={classes.errorChartContainer}>
            <div className={classes.title}>Carrier Name Data</div>
            <Doughnut data={{
                labels: carriers ? Object.keys(carriers).map((key) => key) : [],
                datasets: [
                  {
                    label: '# of Votes',
                    data: carriers ? Object.keys(carriers).map((key) => carriers[key]) : [],
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                  },
                ],
              }} 
            />
          </div>
          <div className={classes.errorChartContainer}>
          <div className={classes.title}>Caller Errors Data</div>
            <Doughnut data={{
                labels: callerErrors ? Object.keys(callerErrors).map((key) => key === "null" ? "No error": key) : [],
                datasets: [
                  {
                    label: '# of Votes',
                    data: callerErrors ? Object.keys(callerErrors).map((key) => callerErrors[key] === "null" ? "No error": callerErrors[key]) : [],
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                  },
                ],
              }} 
            />
          </div>
          <div className={classes.errorChartContainer}>
            <div className={classes.title}>Carrier Errors Data</div>
            <Doughnut data={{
                labels: carrierErrors ? Object.keys(carrierErrors).map((key) => key === "null" ? "No error": key) : [],
                datasets: [
                  {
                    label: '# of Votes',
                    data: carrierErrors ? Object.keys(carrierErrors).map((key) => carrierErrors[key] === "null" ? "No error": carrierErrors[key]) : [],
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                  },
                ],
              }} 
            />
          </div>
        </div> */}
      </>
      :
      <Navigate to='/login'  />
    );
  } else {
    return (
      <LoadingScreen />
    );
  }
}
