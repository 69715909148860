import types from '../types'
const { GoogleSpreadsheet } = require('google-spreadsheet');

async function unsubscribeBotsGet(dispatch, payload) {
    const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SHEET_UNSUBSCRIBE_ID);
    // use service account creds
    await doc.useServiceAccountAuth(require('channelauto-7635c08d0942.json'));
    await doc.loadInfo();
    console.log("payload", payload)
    let data = await doc.sheetsById[payload.workspace.unsubscribe_index].getRows()
    console.log("data", data)
    // const dataAppointment = await doc.sheetsById[209176258].getRows(); //gets from Sheet Appointment Set
    // console.log("data", data)
    // console.log(doc)
    //const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id]
    // let sheet = {}
    // for(let i = 0; i < doc.sheetCount; i ++) {
    //     // console.log("Sheet" + (i + 1))
    //     let data = doc.sheetsByIndex[i]
    //     let rows =  await data.getRows()
    //     // console.log(rows)
    //    sheet = {
    //        ...sheet,
    //        ["Service " + (i + 1)] : rows
    //    }
    // }

    // console.log(sheet)
    
    dispatch({ type: types.UNSUBSCRIBED_BOTS_GETS, payload: data })
}

export default unsubscribeBotsGet
