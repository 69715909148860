import SalesDataDashboard from 'pages/SalesDataDashboard'
import LeadInsightDashboard from 'pages/LeadInsightDashboard'
import TeamTrackerDashboard from 'pages/TeamTrackerDashboard'
// import Login from 'pages/Login'
import Numbers from 'pages/Numbers'
// import Form from 'pages/Form'

// eslint-disable-next-line
export default [
  {
    path: '/dashboard/sales_data',
    component: SalesDataDashboard,
    exact: true,
  },
  {
    path: '/dashboard/sales_data/:id',
    component: SalesDataDashboard,
    exact: false,
  },
  {
    path: '/dashboard/lead_insight/:id',
    component: LeadInsightDashboard,
    exact: false,
  },
  {
    path: '/dashboard/lead_insight',
    component: LeadInsightDashboard,
    exact: true,
  },
  {
    path: '/dashboard/team_tracker/:id',
    component: TeamTrackerDashboard,
    exact: false,
  },
  {
    path: '/dashboard/team_tracker',
    component: TeamTrackerDashboard,
    exact: true,
  },
  // {
  //   path: '/login',
  //   component: Login,
  //   exact: true,
  // },
  {
    path: '/numbers',
    component: Numbers,
    exact: true,
  },
  // {
  //   path: '/form',
  //   component: Form,
  //   exact: true,
  // },
  // {
  //   path: '/',
  //   component: Form,
  //   exact: true,
  // },
]
